<template>
  <v-row>
    <v-col sm="12" md="12">
      <v-card>
        <v-card-title>
          <v-icon class="mr-2">mdi-store</v-icon>
          Clients
          <v-spacer></v-spacer>
          <v-text-field
            v-model="paginationOptions.search"
            class="mr-3"
            append-icon="mdi-magnify"
            label="Buscar"
            placeholder="Informe o Nome Fantasia ou Razão social"
            single-line
            hide-details
            @keydown="buscar()"
            @click:append="buscar()"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-switch
            v-model="clients.active"
            :label=" `Exibindo ${clients.active? 'Ativos': 'Inativos'}`"
            single-line
            hide-details
            class="mr-3"
            style="width: 200px"
            @change="buscar()"
          ></v-switch>
          <v-btn
            :to="{ name: 'clientCreate' }"
            color="#3D1159"
            dark
            title="Criar Client"
          >
            <v-icon class="mr-1">mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </v-card-title>
        <v-row v-if="!clients.items">
          <v-col md="12">
            <v-alert
              dense
              outlined
              type="warning"
            >
              Clientes não encontrados
            </v-alert>
          </v-col>
        </v-row>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="filteredClientsItems()"
              :options.sync="options"
              :server-items-length="clients.totalItems"
              :search.sync="paginationOptions.search"
              item-key="id"
              class="elevation-1"
              v-show="clients.items"
              :footer-props="{
                        'items-per-page-options': [10, 25, 50, 75, 100]
                      }"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#3D1159"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :to="{
                        name: 'client',
                        params: { id: item.id }
                      }"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          
      </v-card>
    </v-col>
  </v-row>    
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {CLIENT_SEARCH, } from "@/store/actions/client.type";
import {
  CLIENTS_RESET,
  SET_CLIENT_PAGINATION
} from "@/store/mutations/client.type";

export default {
  name: "Clients",
  props: {
    source: String
  },

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(CLIENTS_RESET);
    //await store.dispatch("filterList");
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(CLIENTS_RESET),
      store.dispatch(CLIENT_SEARCH)
    ]).then(() => {
      next();
    });
  },
  async beforeDestroy() {
        if(!this.$route.path.includes('/clients')){
            localStorage.removeItem('clientsOptions')
            localStorage.removeItem('clientPaginationOptions')
        }
    },

  data: () => ({
    headers: [
      
      { text: "Razão Social", value: "companyName" },
      { text: "Nome Fantasia", value: "fantasyName" },
      { text: "CNPJ", value: "cnpj" },
      { text: "Visualizar", value: "actions", align: "center", sortable: false }
    ],
    selected: null,
    options:{},
    paginationOptions: {},
    //rules
    textRules: [
      // v => !!v || "Este campo é obrigatório",
      v =>
        (v && v <= 900) || "O valor deste campo não pode exceder mais que 900"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ]
  }),

  methods: {

    buscar() {
      const {sortBy, sortDesc, itemsPerPage, page} = this.options
      this.clientPagination.search = this.paginationOptions.search
        store.commit(SET_CLIENT_PAGINATION, {
        page: page,
        itemsPerPage: itemsPerPage,
        orderBy: sortBy,
        sortDesc: sortDesc[0]
      })
      store.dispatch(CLIENT_SEARCH);
    },
    
    filteredClientsItems() { 
        const items = this.clients.items
        if(this.clientPagination.search){
            const search = this.clientPagination.search.toLowerCase()
            this.options.itemsPerPage = this.clients.totalItems
            this.options.page = 1
            const exactMatches = [];
            const partialMatches = [];

            items.forEach(item => {
                const itemValues = Object.values(item).map(value => String(value).toLowerCase());
                const isExactMatch = itemValues.some(value => value.startsWith(search));
                const isPartialMatch = itemValues.some(value => value.includes(search));
                
                if (isExactMatch) {
                    exactMatches.push(item);
                } else if (isPartialMatch) {
                    partialMatches.push(item);
                }
            });

            return [...exactMatches,...partialMatches];
        }
        else {
            if(this.options.itemsPerPage == this.clients.totalItems){
                this.options.itemsPerPage = 10
            }
            return items;
        } 
    }

  },
  mounted() {
      if (localStorage.getItem('clientsOptions')){
          this.options = JSON.parse(localStorage.getItem('clientsOptions'))
      }
      if (localStorage.getItem('clientPaginationOptions')){
          this.paginationOptions = JSON.parse(localStorage.getItem('clientPaginationOptions'))
      }
  },
  watch: {
    options: {
      handler(newOptions) {
        this.buscar()
        const parsed = JSON.stringify(newOptions)
        localStorage.setItem('clientsOptions', parsed)
      },
      deep: true,
    },
    paginationOptions: {
          handler(newSearch) {
              this.buscar()
              const parsed = JSON.stringify(newSearch)
              localStorage.setItem('clientPaginationOptions', parsed)
          },
          deep: true,
      }
  },
  computed: {
    ...mapGetters(["clients", "clientPagination"])
  }
};
</script>
